<template>
<v-app>
    <v-app-bar app height="70" class="app_bar" color="orange lighten-4">
        <v-col align="center"  >
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="/logo.png" transition="scale-transition" width="180" />
        </v-col>
    </v-app-bar>

    <v-main>
        <MainPage :fontSize=fontSize :language=value />
    </v-main>
    <!-- Bottom menu -->
    <v-bottom-navigation app classs="bottom_bar">

        <v-select class="pl-4" @change="changeLanguage()" label="Idioma - Language" :items="items" item-text="language" item-value="id" v-model="value" return-object prepend-icon="mdi-translate-variant"></v-select>
        <v-btn icon @click="decreaseFontSize()">
            <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-icon>mdi-format-size</v-icon>
        <v-btn icon @click="increaseFontSize()">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-bottom-navigation>
</v-app>
</template>

<script>
import MainPage from './components/MainPage';

export default {
    name: 'App',

    components: {
        MainPage,
    },

    data: () => ({
        items: [{
                id: 0,
                language: "Español",
                code: "esp"
            },
            {
                id: 1,
                language: "English",
                code: "eng"
            },
            {
                id: 2,
                language: "Fançaise",
                code: "fra"
            }
        ],
        value: {
                id: 1,
                language: "English",
                code:"esp"
            },
        size: 18,
        fontSize: "18px"
        //
    }),
    methods: {
        increaseFontSize() {
            this.size = this.size + 1;
            this.fontSize = this.size + "px"
            localStorage.setItem("fontSize", this.size + "px")
        },
        decreaseFontSize() {
            this.size = this.size - 1;
            this.fontSize = this.size + "px"
            localStorage.setItem("fontSize", this.size + "px")
        },
        changeLanguage() {
            localStorage.setItem("language", JSON.stringify(this.value))
        }

    },
    mounted() {
        if (localStorage.getItem("language") != null) {
            this.value = JSON.parse(localStorage.getItem("language"))
            this.fontSize = localStorage.getItem("fontSize")
        }
    }
};
</script>
<style>
.app_bar{
    color:rgb(178, 177, 177)!important;
}
.bottom_bar{  
        touch-action: manipulation;
}
</style>
