<template>
<div app>
    <v-card max-width="800px" v-for="cedula in cedulario" :key="cedula.id" :id=cedula.id class="offset" elevation="4">
        <v-card-title>
            <v-row>
                <v-col cols="10"><div class="titulo">{{cedula.textos[code].titulo}}</div></v-col><v-col cols="2" class="id">id:{{cedula.id}}</v-col>
            </v-row>
        </v-card-title>
        <v-img lazy height="250" :src="cedula.imagen"></v-img>
        <v-card-text class="psa-0">
            <div class="subtitulo" :style="{ 'font-size': fontSize }">{{cedula.textos[code].subtitulo}}</div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <div :class="cedula.tipo" :style="{ 'font-size': fontSize }">{{cedula.textos[code].texto}}</div>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <div class="box"></div>
    </v-card>
</div>
</template>

<script>
export default {
    name: 'MainPage',
    props: ['fontSize', 'language'],
    data() {
        return {

            cedula: 1,
            cedulario: [],
        };
    },
    mounted() {
        fetch('./cedulas.json')
            .then((response) => response.json())
            .then((json) => this.cedulario = json.cedulario);
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('cedula')) {
            setTimeout(() => {
                let cedula = urlParams.get('cedula')
                let element = document.getElementById(cedula);
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 400);
        }

    },

    methods: {

    },
    computed: {
        code() {
            return this.language.code
        }

    }
};
</script>

<style>
@font-face {
    font-family: SourceCodePro-VariableFont_wght;
    src: url("../../public/fonts/SourceCodePro-VariableFont_wght.ttf");
}

.box {
    margin-top: 20px;
    background-color: chartreuse;
    border: 0px black solid;
}

.texto {
    font-family: 'SourceCodePro-VariableFont_wght';
}

.titulo {
    word-break: keep-all;
    overflow-wrap: break-word;
    font-family: 'SourceCodePro-VariableFont_wght';
    font-size: 1em;
}

.subtitulo {
    font-family: 'SourceCodePro-VariableFont_wght';
    font-size: .9em;
}

.offset {
    scroll-margin-top: 70px;
}
.id{
    font-size: .7em;
    color: rgb(103, 103, 103);
}
.tematica_01 {
    font-family: 'SourceCodePro-VariableFont_wght';
    background-color: rgb(255, 145, 0);
    color: rgb(255, 255, 255);
    padding: 10px;
}

.tematica_01::first-letter {
    -webkit-initial-letter: 3;
    initial-letter: 3;
    color: rgb(117, 206, 65);
    font-weight: bold;
    margin-right: .5em;
}

.tematica_02 {
    font-family: 'SourceCodePro-VariableFont_wght';
    background-color: rgb(117, 206, 65);
    color: rgb(255, 255, 255);
    padding: 10px;
}

.tematica_02::first-letter {
    -webkit-initial-letter: 3;
    initial-letter: 3;
    color: rgb(255, 143, 18);
    font-weight: bold;
    margin-right: .75em;
}

.subtematica_01 {
    font-family: 'SourceCodePro-VariableFont_wght';
    background-color: rgb(0, 137, 149);
    color: rgb(255, 255, 255);
    padding: 10px;
}

.subtematica_01::first-letter {
    -webkit-initial-letter: 3;
    initial-letter: 3;
    color: rgb(243, 205, 0);
    font-weight: bold;
    margin-right: .75em;
}
</style>
